import { createMuiTheme } from '@material-ui/core/styles'
import set from 'lodash/set'
import { getMetaValue } from './utils'

__webpack_nonce__ = getMetaValue('csp-nonce') // required for styled-components to work

// A hackish way of reseting theme
// Used due to the amount of components directly importing theme constant theme - would very long to rewrite them all
const SIZE_FACTOR_STORAGE_KEY = 'odsSizeFactor'

export enum SizeFactor {
  small = 'small',
  normal = 'normal',
  large = 'large'
}

export let SIZE_FACTOR = SizeFactor.normal
try {
  SIZE_FACTOR = localStorage.getItem(SIZE_FACTOR_STORAGE_KEY) as SizeFactor || SizeFactor.normal
} catch (error) {
  // noop
}

export const SMALL_SIZE_FACTOR = SIZE_FACTOR === SizeFactor.small

export const setSizeFactor = (sizeFactor: SizeFactor) => {
  try {
    localStorage.setItem(SIZE_FACTOR_STORAGE_KEY, sizeFactor)
    window.location.reload()
  } catch (error) {
    // noop
  }
}

const fontSizes = {
  [SizeFactor.small]: 10,
  [SizeFactor.normal]: 12,
  [SizeFactor.large]: 14
}

const spacings = {
  [SizeFactor.small]: 4,
  [SizeFactor.normal]: 8,
  [SizeFactor.large]: 12
}

let themeSettings = {
  palette: {
    primary: {
      main: '#62bb21'
    },
    secondary: {
      main: '#073c87'
    }
  },
  typography: {
    fontSize: fontSizes[SIZE_FACTOR]
  },
  spacing: spacings[SIZE_FACTOR],
}

if (SMALL_SIZE_FACTOR) {
  set(themeSettings, ['props', 'MuiTable', 'size'], 'small')
  set(themeSettings, ['props', 'MuiTable', 'padding'], 'none')
}

const theme = createMuiTheme(themeSettings)

export default theme
