import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'errorLogging'
import 'i18n'

import React from 'react'
import ReactDOM from 'react-dom'

import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiThemeProvider } from '@material-ui/core/styles'

import theme from 'theme'
import logo from 'images/logo.png'

import styled from 'styled-components'
import { t } from 'i18n'

import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'

const LoginPrompt = styled(Card)`
  width: 287.5px;
  margin: 20vh auto;
`

const TitleImage = styled(CardMedia)`
  height: 110px;
`

const rootEelement = document.getElementById('root')

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
      <LoginPrompt>
        <TitleImage image={logo} title={t('title')} />
        <CardActions>
          <Button color='primary' component='a' href={rootEelement.dataset.href}>{t('sign_in')}</Button>
        </CardActions>
      </LoginPrompt>
  </MuiThemeProvider>,
  rootEelement
)
