import { init } from '@sentry/browser'
import { getMetaValue } from './utils'

init({
  dsn: getMetaValue('sentry-dsn'),
  environment: getMetaValue('environment'),
  ignoreErrors: [
    /ResizeObserver loop limit exceeded/,
  ]
})
