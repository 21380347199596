import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import isValid from 'date-fns/isValid'
import { GQLDate, GQLISO8601DateTime, GQLOrderStatusEnum } from 'schema'
import { WithSnackbarProps } from 'notistack';
import { ApolloError } from 'apollo-boost'
import each from 'lodash/each'

export function formatDate(date: GQLISO8601DateTime | GQLDate, mask = 'dd.MM.yyyy HH:mm'): string {
  const parsedDate = parseISO(date)

  if (isValid(parsedDate)) {
    return format(parsedDate, mask)
  } else {
    return ''
  }
}

export function formatLiters(liters: number) {
  return `${liters}L`
}

export const activeOrderStatuses = [
  GQLOrderStatusEnum.new,
  GQLOrderStatusEnum.approved,
  GQLOrderStatusEnum.estimated,
  GQLOrderStatusEnum.estimate_approved,
  GQLOrderStatusEnum.planned
]

export function getMetaValue(name: string): string {
  const metaTag = document.querySelector(`meta[name=${name}]`)

  if (metaTag) {
    return metaTag.getAttribute('content')
  } else {
    return ''
  }
}

export function notifyGraphqlError(error: ApolloError, enqueueSnackbar: WithSnackbarProps["enqueueSnackbar"]): void {
  each(error.graphQLErrors, gqlError => {
    enqueueSnackbar(gqlError.message, { variant: 'error'})
  })
}
